const teachingTimetable = {
  ASSIGN_TEACHING_TIMETABLE_REQUEST: 'ASSIGN_TEACHING_TIMETABLE_REQUEST',
  ASSIGN_TEACHING_TIMETABLE_SUCCESS: 'ASSIGN_TEACHING_TIMETABLE_SUCCESS',
  ASSIGN_TEACHING_TIMETABLE_ERROR: 'ASSIGN_TEACHING_TIMETABLE_ERROR',

  GET_TEACHING_TIMETABLE_REQUEST: 'GET_TEACHING_TIMETABLE_REQUEST',
  GET_TEACHING_TIMETABLE_SUCCESS: 'GET_TEACHING_TIMETABLE_SUCCESS',
  GET_TEACHING_TIMETABLE_ERROR: 'GET_TEACHING_TIMETABLE_ERROR',

  SEARCH_TEACHING_TIMETABLE_REQUEST: 'SEARCH_TEACHING_TIMETABLE_REQUEST',
  SEARCH_TEACHING_TIMETABLE_SUCCESS: 'SEARCH_TEACHING_TIMETABLE_SUCCESS',
  SEARCH_TEACHING_TIMETABLE_ERROR: 'SEARCH_TEACHING_TIMETABLE_ERROR',

  TEACHING_TIMETABLE_BY_DEPARTMENT_REQUEST:
    'TEACHING_TIMETABLE_BY_DEPARTMENT_REQUEST',
  TEACHING_TIMETABLE_BY_DEPARTMENT_SUCCESS:
    'TEACHING_TIMETABLE_BY_DEPARTMENT_SUCCESS',
  TEACHING_TIMETABLE_BY_DEPARTMENT_ERROR:
    'TEACHING_TIMETABLE_BY_DEPARTMENT_ERROR',

  TEACHING_TIMETABLE_BY_FACULTY_REQUEST:
    'TEACHING_TIMETABLE_BY_FACULTY_REQUEST',
  TEACHING_TIMETABLE_BY_FACULTY_SUCCESS:
    'TEACHING_TIMETABLE_BY_FACULTY_SUCCESS',
  TEACHING_TIMETABLE_BY_FACULTY_ERROR: 'TEACHING_TIMETABLE_BY_FACULTY_ERROR',

  DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_REQUEST:
    'DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_REQUEST',
  DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_SUCCESS:
    'DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_SUCCESS',
  DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_ERROR:
    'DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_ERROR',

  UPLOAD_TEACHING_TIMETABLE_REQUEST: 'UPLOAD_TEACHING_TIMETABLE_REQUEST',
  UPLOAD_TEACHING_TIMETABLE_SUCCESS: 'UPLOAD_TEACHING_TIMETABLE_SUCCESS',
  UPLOAD_TEACHING_TIMETABLE_ERROR: 'UPLOAD_TEACHING_TIMETABLE_ERROR',

  DELETE_TEACHING_TIMETABLE_REQUEST: 'DELETE_TEACHING_TIMETABLE_REQUEST',
  DELETE_TEACHING_TIMETABLE_SUCCESS: 'DELETE_TEACHING_TIMETABLE_SUCCESS',
  DELETE_TEACHING_TIMETABLE_ERROR: 'DELETE_TEACHING_TIMETABLE_ERROR',

  EDIT_TEACHING_TIMETABLE_REQUEST: 'EDIT_TEACHING_TIMETABLE_REQUEST',
  EDIT_TEACHING_TIMETABLE_SUCCESS: 'EDIT_TEACHING_TIMETABLE_SUCCESS',
  EDIT_TEACHING_TIMETABLE_ERROR: 'EDIT_TEACHING_TIMETABLE_ERROR',

  SHOW_ASSIGN_LECTURER_MODAL: 'SHOW_ASSIGN_LECTURER_MODAL',

  GET_PROJECTED_LECTURER_TEACHING_LOAD_REQUEST:
    'GET_PROJECTED_LECTURER_TEACHING_LOAD_REQUEST',
  GET_PROJECTED_LECTURER_TEACHING_LOAD_SUCCESS:
    'GET_PROJECTED_LECTURER_TEACHING_LOAD_SUCCESS',
  GET_PROJECTED_LECTURER_TEACHING_LOAD_ERROR:
    'GET_PROJECTED_LECTURER_TEACHING_LOAD_ERROR',

  SET_SHOW_LECTURER_TEACHING_LOAD_MODAL:
    'SET_SHOW_LECTURER_TEACHING_LOAD_MODAL',
  SET_LECTURER_TEACHING_LOAD_DATA: 'SET_LECTURER_TEACHING_LOAD_DATA',

  DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_REQUEST:
    'DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_REQUEST',
  DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_SUCCESS:
    'DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_SUCCESS',
  DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_ERROR:
    'DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_ERROR',

  SET_VIEW_LECTURERS: 'SET_VIEW_LECTURERS',

  assignLecturer: (data, context, actionType) => ({
    type: teachingTimetable.ASSIGN_TEACHING_TIMETABLE_REQUEST,
    data,
    context,
    actionType,
  }),

  setViewLecturers: (data) => ({
    type: teachingTimetable.SET_VIEW_LECTURERS,
    data,
  }),

  downloadTeachingTimetable: (context) => ({
    type: teachingTimetable.DOWNLOAD_PROGRAMME_TEACHING_TIMETABLE_REQUEST,
    context,
  }),

  getTeachingTimetable: (context) => ({
    type: teachingTimetable.GET_TEACHING_TIMETABLE_REQUEST,
    context,
  }),

  searchTeachingTimetable: (context) => ({
    type: teachingTimetable.SEARCH_TEACHING_TIMETABLE_REQUEST,
    context,
  }),

  getTeachingTimetableByDepartment: (context) => ({
    type: teachingTimetable.TEACHING_TIMETABLE_BY_DEPARTMENT_REQUEST,
    context,
  }),

  getTeachingTimetableByFaculty: (context) => ({
    type: teachingTimetable.TEACHING_TIMETABLE_BY_FACULTY_REQUEST,
    context,
  }),

  downloadTeachingTimetableTemplate: () => ({
    type: teachingTimetable.DOWNLOAD_TEACHING_TIMETABLE_TEMPLATE_REQUEST,
  }),

  uploadTeachingTimetable: (data) => ({
    type: teachingTimetable.UPLOAD_TEACHING_TIMETABLE_REQUEST,
    data,
  }),

  deleteTeachingTimetable: (id, context) => ({
    type: teachingTimetable.DELETE_TEACHING_TIMETABLE_REQUEST,
    id,
    context,
  }),

  editTeachingTimetable: (data, id, context) => ({
    type: teachingTimetable.EDIT_TEACHING_TIMETABLE_REQUEST,
    data,
    id,
    context,
  }),

  showAssignLecturerModal: (data = false) => ({
    type: teachingTimetable.SHOW_ASSIGN_LECTURER_MODAL,
    data,
  }),

  getProjectedLecturerTeachingLoad: (context) => ({
    type: teachingTimetable.GET_PROJECTED_LECTURER_TEACHING_LOAD_REQUEST,
    context,
  }),

  setShowLecturerTeachingLoadModal: (data = false) => ({
    type: teachingTimetable.SET_SHOW_LECTURER_TEACHING_LOAD_MODAL,
    data,
  }),

  setLecturerTeachingLoadData: (data) => ({
    type: teachingTimetable.SET_LECTURER_TEACHING_LOAD_DATA,
    data,
  }),
};

export default teachingTimetable;
