import axios from 'axios';
import FileSaver from 'file-saver';
import { fork, put, takeLatest } from 'redux-saga/effects';
import { studentActions, studentsActions } from '../../actions';

function* downloadStudentTemplate() {
  try {
    yield axios({
      url: '/students-mgt/students/download-template/',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-STUDENT-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: studentActions.DOWNLOAD_STUDENT_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: studentActions.DOWNLOAD_STUDENT_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* downloadBulkUpdateTemplate() {
  try {
    yield axios({
      url: '/students-mgt/students/download-bulk-update-template',
      method: 'POST',
      responseType: 'blob',
    }).then((response) => {
      FileSaver.saveAs(
        new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        }),
        `${process.env.REACT_APP_INSTITUTION_NAME}-BULK-UPDATE-STUDENT-UPLOAD-TEMPLATE.xlsx`
      );
    });
    yield put({
      type: studentActions.DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_SUCCESS,
      data: { server: { message: 'Template downloaded!', status: true } },
    });
  } catch (error) {
    yield put({
      type: studentActions.DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadStudents(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/upload',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 1000000,
    });

    yield put({
      type: studentActions.UPLOAD_STUDENT_TEMPLATE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentActions.UPLOAD_STUDENT_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* verifyStudentUploadTemplate(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/verify-student-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 1000000,
    });

    yield put({
      type: studentActions.VERIFY_UPLOAD_STUDENT_TEMPLATE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentActions.VERIFY_UPLOAD_STUDENT_TEMPLATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* uploadBulkUpdateStudents(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/upload-bulk-update-template',
      method: 'POST',
      data: actions.data,
      headers: { 'Content-Type': 'multipart/form-data' },
      timeout: 1000000,
    });
    yield put({
      type: studentActions.UPLOAD_BULK_STUDENT_UPDATE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentActions.UPLOAD_BULK_STUDENT_UPDATE_ERROR,
      error: error.data ? error.data : error,
    });
  }
}

function* getPendingStudents() {
  try {
    const response = yield axios({
      url: '/students-mgt/students/approvals/uploaded-batches',
      method: 'GET',
    });
    yield put({
      type: studentActions.GET_PENDING_STUDENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentActions.GET_PENDING_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* getBatchStudents(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/approvals/uploaded-students',
      method: 'GET',
      params: { batchNumber: actions.data },
    });
    yield put({
      type: studentActions.GET_BATCH_STUDENTS_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentActions.GET_BATCH_STUDENTS_ERROR,
      error: error.data,
    });
  }
}

function* getStudentDissertation(actions) {
  try {
    const { data } = actions;
    const response = yield axios({
      url: '/students-mgt/programme-details/dissertations',
      method: 'GET',
      params: data,
    });
    yield put({
      type: studentActions.GET_STUDENTS_DISSERTATION_SUCCESS,
      data: response.data,
      ...data,
    });
  } catch (error) {
    yield put({
      type: studentActions.GET_STUDENTS_DISSERTATION_ERROR,
      error: error.data,
    });
  }
}

function* updateStudentDissertation(actions) {
  try {
    const { data, id, programmeId } = actions;
    const response = yield axios({
      url: `/students-mgt/programme-details/dissertations/${id}`,
      method: 'PUT',
      data,
    });
    yield put({
      type: studentActions.UPDATE_STUDENTS_DISSERTATION_SUCCESS,
      data: response.data,
      ...data,
    });
    yield put({
      type: studentActions.GET_STUDENTS_DISSERTATION_REQUEST,
      data: { programme_id: programmeId },
    });
    yield put({
      type: 'SHOW_MODAL',
      payload: false,
    });
  } catch (error) {
    yield put({
      type: studentActions.UPDATE_STUDENTS_DISSERTATION_ERROR,
      error: error.data,
    });
  }
}

function* getMyStudentBatches(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/approvals/batches-user',
      method: 'GET',
      params: actions.params,
    });
    yield put({
      type: studentActions.GET_MY_STUDENT_BATCHES_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentActions.GET_MY_STUDENT_BATCHES_ERROR,
      error: error.data,
    });
  }
}

function* searchStudent(actions) {
  try {
    const url =
      actions.fetchAll === false
        ? '/students-mgt/students-records'
        : '/students-mgt/students-records/including-previous-programmes';

    const response = yield axios({
      url,
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: studentActions.SEARCH_STUDENT_SUCCESS,
      data: response.data,
      context: actions.context,
    });
  } catch (error) {
    yield put({
      type: studentActions.SEARCH_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingStudents(actions) {
  try {
    const response = yield axios({
      url: '/students-mgt/students/approvals',
      method: 'POST',
      data: actions.data,
    });

    yield put({
      type: studentActions.APPROVE_PENDING_STUDENT_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentActions.GET_PENDING_STUDENTS_REQUEST,
    });
    yield put({
      type: studentActions.GET_BATCH_STUDENTS_REQUEST,
      data: actions.batchNumber,
    });
    if (actions.context) {
      yield put({
        type: studentActions.GET_MY_STUDENT_BATCHES_REQUEST,
        params: actions.context,
      });
    }
  } catch (error) {
    yield put({
      type: studentActions.APPROVE_PENDING_STUDENT_ERROR,
      error: error.data,
    });
  }
}

function* approvePendingBatch(actions) {
  try {
    const response = yield axios({
      url: `/students-mgt/students/approvals/approve-by-batch`,
      method: 'POST',
      params: { batchNumber: actions.data },
    });
    yield put({
      type: studentActions.APPROVE_PENDING_BATCH_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentActions.GET_PENDING_STUDENTS_REQUEST,
    });
    if (actions.context) {
      yield put({
        type: studentActions.GET_MY_STUDENT_BATCHES_REQUEST,
        params: actions.context,
      });
    }
  } catch (error) {
    yield put({
      type: studentActions.APPROVE_PENDING_BATCH_ERROR,
      error: error.data,
    });
  }
}

function* getPendingAccountStatusChanges() {
  try {
    const response = yield axios({
      url: '/students-mgt/students/student-account-status-change-approvals',
      method: 'GET',
    });
    yield put({
      type: studentActions.GET_PENDING_ACCOUNT_STATUS_CHANGE_SUCCESS,
      data: response.data,
    });
  } catch (error) {
    yield put({
      type: studentActions.GET_PENDING_ACCOUNT_STATUS_CHANGE_ERROR,
      error: error.data,
    });
  }
}

function* approveAccountStatusChange(actions) {
  try {
    const response = yield axios({
      url: `/students-mgt/students/approve-update-student-account-statuses`,
      method: 'POST',
      data: actions.data,
    });
    yield put({
      type: studentActions.APPROVE_PENDING_ACCOUNT_STATUS_CHANGE_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentActions.GET_PENDING_ACCOUNT_STATUS_CHANGE_REQUEST,
    });
  } catch (error) {
    yield put({
      type: studentActions.APPROVE_PENDING_ACCOUNT_STATUS_CHANGE_ERROR,
      error: error.data,
    });
  }
}

function* deletePendingBatch(actions) {
  try {
    const response = yield axios({
      url: `/students-mgt/students/approvals/delete-by-batch/${actions.batchNumber}`,
      method: 'DELETE',
      timeout: 1200000,
    });
    yield put({
      type: studentActions.DELETE_PENDING_BATCH_SUCCESS,
      data: response.data,
    });
    yield put({
      type: 'SHOW_DELETE_MODAL',
      payload: false,
    });
    yield put({
      type: studentActions.GET_PENDING_STUDENTS_REQUEST,
    });
  } catch (error) {
    yield put({
      type: studentActions.DELETE_PENDING_BATCH_ERROR,
      error: error.data,
    });
  }
}

function* deleteStudentsFromSRM(actions) {
  try {
    const { payload, context } = actions;
    const response = yield axios({
      url: `/students-mgt/students/delete-from-srm`,
      method: 'DELETE',
      data: payload,
    });
    yield put({
      type: studentActions.DELETE_STUDENT_FROM_SRM_SUCCESS,
      data: response.data,
    });
    yield put({
      type: studentActions.SHOW_DELETE_STUDENT_FROM_SRM_MODAL,
      payload: false,
    });
    yield put({
      type: studentsActions.GET_CONTEXT_STUDENTS_REQUEST,
      context,
    });
  } catch (error) {
    yield put({
      type: studentActions.DELETE_STUDENT_FROM_SRM_ERROR,
      error: error.data,
    });
  }
}

function* getPrintedIdCards(actions) {
  try {
    const response = yield axios({
      url: '/id-tracker/printed',
      method: 'GET',
      params: actions.context,
    });
    yield put({
      type: studentActions.GET_PRINTED_STUDENT_ID_CARDS_SUCCESS,
      data: response.result,
    });
  } catch (error) {
    yield put({
      type: studentActions.GET_PRINTED_STUDENT_ID_CARDS_ERROR,
      error: error.data,
    });
  }
}

function* watchDownloadStudentTemplate() {
  yield takeLatest(
    studentActions.DOWNLOAD_STUDENT_TEMPLATE_REQUEST,
    downloadStudentTemplate
  );
}

function* watchDownloadBulkUpdateTemplate() {
  yield takeLatest(
    studentActions.DOWNLOAD_BULK_UPDATE_STUDENT_TEMPLATE_REQUEST,
    downloadBulkUpdateTemplate
  );
}

function* watchSearchStudent() {
  yield takeLatest(studentActions.SEARCH_STUDENT_REQUEST, searchStudent);
}

function* watchUploadStudentTemplate() {
  yield takeLatest(
    studentActions.UPLOAD_STUDENT_TEMPLATE_REQUEST,
    uploadStudents
  );
}

function* watchVerifyUploadStudentTemplate() {
  yield takeLatest(
    studentActions.VERIFY_UPLOAD_STUDENT_TEMPLATE_REQUEST,
    verifyStudentUploadTemplate
  );
}

function* watchUploadBulkUpdateStudents() {
  yield takeLatest(
    studentActions.UPLOAD_BULK_STUDENT_UPDATE_REQUEST,
    uploadBulkUpdateStudents
  );
}

function* watchGetPendingStudents() {
  yield takeLatest(
    studentActions.GET_PENDING_STUDENTS_REQUEST,
    getPendingStudents
  );
}

function* watchGetBatchStudents() {
  yield takeLatest(studentActions.GET_BATCH_STUDENTS_REQUEST, getBatchStudents);
}

function* watchGetStudentDissertation() {
  yield takeLatest(
    studentActions.GET_STUDENTS_DISSERTATION_REQUEST,
    getStudentDissertation
  );
}

function* watchUpdateStudentDissertation() {
  yield takeLatest(
    studentActions.UPDATE_STUDENTS_DISSERTATION_REQUEST,
    updateStudentDissertation
  );
}

function* watchGetMyStudentBatches() {
  yield takeLatest(
    studentActions.GET_MY_STUDENT_BATCHES_REQUEST,
    getMyStudentBatches
  );
}

function* watchApprovePendingStudents() {
  yield takeLatest(
    studentActions.APPROVE_PENDING_STUDENT_REQUEST,
    approvePendingStudents
  );
}

function* watchGetPendingAccountStatusChanges() {
  yield takeLatest(
    studentActions.GET_PENDING_ACCOUNT_STATUS_CHANGE_REQUEST,
    getPendingAccountStatusChanges
  );
}

function* watchApproveAccountStatusChange() {
  yield takeLatest(
    studentActions.APPROVE_PENDING_ACCOUNT_STATUS_CHANGE_REQUEST,
    approveAccountStatusChange
  );
}

function* watchApprovePendingBatch() {
  yield takeLatest(
    studentActions.APPROVE_PENDING_BATCH_REQUEST,
    approvePendingBatch
  );
}

function* watchDeletePendingBatch() {
  yield takeLatest(
    studentActions.DELETE_PENDING_BATCH_REQUEST,
    deletePendingBatch
  );
}

function* watchDeleteStudentsFromSRM() {
  yield takeLatest(
    studentActions.DELETE_STUDENT_FROM_SRM_REQUEST,
    deleteStudentsFromSRM
  );
}

function* watchGetPrintedIdCards() {
  yield takeLatest(
    studentActions.GET_PRINTED_STUDENT_ID_CARDS_REQUEST,
    getPrintedIdCards
  );
}

export default [
  fork(watchDownloadStudentTemplate),
  fork(watchDownloadBulkUpdateTemplate),
  fork(watchUploadStudentTemplate),
  fork(watchVerifyUploadStudentTemplate),
  fork(watchUploadBulkUpdateStudents),
  fork(watchSearchStudent),
  fork(watchGetPendingStudents),
  fork(watchGetBatchStudents),
  fork(watchGetMyStudentBatches),
  fork(watchApprovePendingStudents),
  fork(watchApprovePendingBatch),
  fork(watchDeletePendingBatch),
  fork(watchDeleteStudentsFromSRM),
  fork(watchGetStudentDissertation),
  fork(watchUpdateStudentDissertation),
  fork(watchGetPrintedIdCards),
  fork(watchApproveAccountStatusChange),
  fork(watchGetPendingAccountStatusChanges),
];
