import { Tag } from 'antd';
import { filter, first, groupBy, isEmpty, map, max, uniq } from 'lodash';
import moment from 'moment/moment';
import PropTypes from 'prop-types';
import React from 'react';
import { Card } from 'react-bootstrap';
import AntDTable from './AntDTable';

function TimeSchedular({ data, ...props }) {
  const timeRange = Array.from({ length: 16 * 2 }, (_, index) => {
    const hour = 6 + Math.floor(index / 2); // Starts from 6 and goes up to 21
    const minutes = index % 2 === 0 ? '00' : '30'; // Alternates between 00 and 30 minutes
    const nextHour = hour + (minutes === '30' ? 1 : 0);
    const nextMinutes = minutes === '00' ? '30' : '00';
    const start = `${`0${hour}`.slice(-2)}:${minutes}`;
    const end = `${`0${nextHour}`.slice(-2)}:${nextMinutes}`;

    return {
      timePeriod: `${`0${hour}`.slice(-2)}:${minutes} - ${`0${nextHour}`.slice(
        -2
      )}:${nextMinutes}`,
      start,
      end,
      id: index,
    };
  });

  const timetable = map(timeRange, (timeR) => {
    const timetableData = filter(data, (schedule) => {
      return (
        moment(timeR.start, 'HH:mm').isSameOrAfter(
          moment(schedule.start_time, 'HH:mm')
        ) &&
        moment(timeR.end, 'HH:mm').isSameOrBefore(
          moment(schedule.end_time, 'HH:mm')
        )
      );
    });

    const assignments = map(timetableData, (tableData) => {
      // calculate duration
      const start = moment(tableData.start_time, 'HH:mm:ss');
      const end = moment(tableData.end_time, 'HH:mm:ss');

      return {
        ...tableData,
        lecturer: `${tableData?.surname} ${tableData?.other_names}`,
        room: `${tableData?.building_name} ${tableData?.room_code}`,
        span: end.diff(start, 'hours'),
      };
    });

    const groupedByDay = groupBy(assignments, 'day');

    return {
      start: timeR?.start,
      end: timeR?.end,
      data: groupedByDay,
    };
  });

  const renderRow = (rowData, day) => {
    const findDayData = rowData?.data?.[day];
    const rowSpan = max(map(findDayData, 'span'));
    const duration = moment().diff(moment().add(rowSpan, 'hour'), 'hours') * -1;

    if (findDayData) {
      return (
        <Card
          body
          className="border-0 bg-transparent text-sm text-center h-100 bg-light"
        >
          <div>
            <strong className="text-primary">
              {uniq(map(findDayData, 'course_unit_code')).join(', ')}
            </strong>
          </div>
          <div>
            <small className="fw-bold text-muted text-uppercase">
              {uniq(map(findDayData, 'lecturer')).join(', ')}
            </small>
          </div>
          <div>
            <strong>{uniq(map(findDayData, 'room')).join(', ')}</strong>
          </div>
          <div>
            {!isEmpty(map(findDayData, 'instructional_type')) && (
              <Tag color="cyan">
                {uniq(map(findDayData, 'instructional_type')).join(', ')}
              </Tag>
            )}
            {!isEmpty(map(findDayData, 'group_name')) && (
              <Tag color="lime">
                {uniq(map(findDayData, 'group_name')).join(', ')}
              </Tag>
            )}
          </div>

          <small className="fw-bold text-danger">
            Duration: {`${duration}Hr${duration === 1 ? '' : 's'}`}
          </small>
        </Card>
      );
    }
    return '--x--';
  };

  const getRowSpan = (rowData, day, idx) => {
    const prevRecord = timetable[idx - 1];
    const nextRecord = timetable[idx + 1];

    const currentData = first(rowData?.data?.[day]);
    const prevData = first(prevRecord?.data?.[day]);
    const nextData = first(nextRecord?.data?.[day]);

    let className = '';

    if (currentData) {
      className = 'bg-light border';
    }

    // if the record is between two records with the same data, hide it
    if (nextData && prevData) {
      if (
        currentData?.course_unit_code &&
        prevData?.course_unit_code &&
        nextData?.course_unit_code
      ) {
        return {
          rowSpan: 0,
          className,
        };
      }
    }

    // check if the current row is same as row below to span it
    if (nextRecord) {
      if (
        nextData?.course_unit_code &&
        currentData?.course_unit_code &&
        currentData?.course_unit_code === nextData?.course_unit_code
      ) {
        return {
          rowSpan: currentData.span * 2,
          className: 'bg-light border',
        };
      }
    }

    // Check if the record above is the same as the record currently being processed to hide it
    if (prevRecord) {
      if (
        prevData?.course_unit_code &&
        currentData?.course_unit_code &&
        currentData?.course_unit_code === prevData?.course_unit_code
      ) {
        return {
          rowSpan: 0,
          className,
        };
      }
    }

    return {
      rowSpan: 1,
      className,
    };
  };

  return (
    <AntDTable
      hideIndex
      bordered
      columns={[
        {
          title: 'TIME',
          key: 'time',
          fixed: 'left',
          dataIndex: 'start',
          width: 100,
          align: 'right',
          render: (start, row) => `${start} - ${row.end}`,
          className: 'text-sm fw-bold text-danger bg-gray',
          onHeaderCell: () => ({
            className: 'bg-white text-center fw-bold',
          }),
          onCell: () => ({
            className: 'border fw-bold',
          }),
          rowScope: 'row',
        },
        {
          title: 'MONDAY',
          key: 'monday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'MONDAY'),
          onCell: (rowData, idx) => getRowSpan(rowData, 'MONDAY', idx),
          className: 'p-0',
        },
        {
          title: 'TUESDAY',
          key: 'tuesday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'TUESDAY'),
          onCell: (rowData, idx) => getRowSpan(rowData, 'TUESDAY', idx),
          className: 'p-0',
        },
        {
          title: 'WEDNESDAY',
          key: 'wednesday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'WEDNESDAY'),
          onCell: (rowData, idx) => getRowSpan(rowData, 'WEDNESDAY', idx),
          className: 'p-0',
        },
        {
          title: 'THURSDAY',
          key: 'thursday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'THURSDAY'),
          onCell: (rowData, idx) => getRowSpan(rowData, 'THURSDAY', idx),
          className: 'p-0',
        },
        {
          title: 'FRIDAY',
          key: 'friday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'FRIDAY'),
          onCell: (rowData, idx) => getRowSpan(rowData, 'FRIDAY', idx),
          className: 'p-0',
        },
        {
          title: 'SATURDAY',
          key: 'saturday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'SATURDAY'),
          onCell: (rowData, idx) => getRowSpan(rowData, 'SATURDAY', idx),
          className: 'p-0',
        },
        {
          title: 'SUNDAY',
          key: 'sunday',
          align: 'center',
          render: (rowData) => renderRow(rowData, 'SUNDAY'),
          onCell: (rowData, idx) => getRowSpan(rowData, 'SUNDAY', idx),
          className: 'p-0',
        },
      ]}
      rowKey="id"
      {...props}
      data={timetable}
    />
  );
}

TimeSchedular.propTypes = {
  data: PropTypes.oneOfType([PropTypes.array]).isRequired,
};

export default TimeSchedular;
