const registrationReports = {
  GET_SUMMARIZED_REPORTS_REQUEST: 'GET_SUMMARIZED_REPORTS_REQUEST',
  GET_SUMMARIZED_REPORTS_SUCCESS: 'GET_SUMMARIZED_REPORTS_SUCCESS',
  GET_SUMMARIZED_REPORTS_ERROR: 'GET_SUMMARIZED_REPORTS_ERROR',

  GET_DETAILED_REPORTS_REQUEST: 'GET_DETAILED_REPORTS_REQUEST',
  GET_DETAILED_REPORTS_SUCCESS: 'GET_DETAILED_REPORTS_SUCCESS',
  GET_DETAILED_REPORTS_ERROR: 'GET_DETAILED_REPORTS_ERROR',

  GET_STUDENTS_REPORTS_RECORDS_REQUEST: 'GET_STUDENTS_REPORTS_RECORDS_REQUEST',
  GET_STUDENTS_REPORTS_RECORDS_SUCCESS: 'GET_STUDENTS_REPORTS_RECORDS_SUCCESS',
  GET_STUDENTS_REPORTS_RECORDS_ERROR: 'GET_STUDENTS_REPORTS_RECORDS_ERROR',

  GET_MODULAR_REGISTRATION_SUMMARY_REQUEST:
    'GET_MODULAR_REGISTRATION_SUMMARY_REQUEST',
  GET_MODULAR_REGISTRATION_SUMMARY_SUCCESS:
    'GET_MODULAR_REGISTRATION_SUMMARY_SUCCESS',
  GET_MODULAR_REGISTRATION_SUMMARY_ERROR:
    'GET_MODULAR_REGISTRATION_SUMMARY_ERROR',

  GET_MODULAR_REGISTRATION_DETAILS_REQUEST:
    'GET_MODULAR_REGISTRATION_DETAILS_REQUEST',
  GET_MODULAR_REGISTRATION_DETAILS_SUCCESS:
    'GET_MODULAR_REGISTRATION_DETAILS_SUCCESS',
  GET_MODULAR_REGISTRATION_DETAILS_ERROR:
    'GET_MODULAR_REGISTRATION_DETAILS_ERROR',

  GET_MODULAR_STUDENT_REGISTRATION_DETAILS_REQUEST:
    'GET_MODULAR_STUDENT_REGISTRATION_DETAILS_REQUEST',
  GET_MODULAR_STUDENT_REGISTRATION_DETAILS_SUCCESS:
    'GET_MODULAR_STUDENT_REGISTRATION_DETAILS_SUCCESS',
  GET_MODULAR_STUDENT_REGISTRATION_DETAILS_ERROR:
    'GET_MODULAR_STUDENT_REGISTRATION_DETAILS_ERROR',

  DOWNLOAD_REGISTERED_STUDENTS_RECORDS_REQUEST:
    'DOWNLOAD_REGISTERED_STUDENTS_RECORDS_REQUEST',
  DOWNLOAD_REGISTERED_STUDENTS_RECORDS_SUCCESS:
    'DOWNLOAD_REGISTERED_STUDENTS_RECORDS_SUCCESS',
  DOWNLOAD_REGISTERED_STUDENTS_RECORDS_ERROR:
    'DOWNLOAD_REGISTERED_STUDENTS_RECORDS_ERROR',

  DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_REQUEST:
    'DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_REQUEST',
  DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_SUCCESS:
    'DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_SUCCESS',
  DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_ERROR:
    'DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_ERROR',

  GET_REGISTRATION_REPORT_SUMMARY_REQUEST:
    'GET_REGISTRATION_REPORT_SUMMARY_REQUEST',
  GET_REGISTRATION_REPORT_SUMMARY_SUCCESS:
    'GET_REGISTRATION_REPORT_SUMMARY_SUCCESS',
  GET_REGISTRATION_REPORT_SUMMARY_ERROR:
    'GET_REGISTRATION_REPORT_SUMMARY_ERROR',

  GET_REGISTRATION_STATISTICS_REQUEST: 'GET_REGISTRATION_STATISTICS_REQUEST',
  GET_REGISTRATION_STATISTICS_SUCCESS: 'GET_REGISTRATION_STATISTICS_SUCCESS',
  GET_REGISTRATION_STATISTICS_ERROR: 'GET_REGISTRATION_STATISTICS_ERROR',

  DOWNLOAD_FACULTY_REGISTRATION_RECORDS_REQUEST:
    'DOWNLOAD_FACULTY_REGISTRATION_RECORDS_REQUEST',
  DOWNLOAD_FACULTY_REGISTRATION_RECORDS_SUCCESS:
    'DOWNLOAD_FACULTY_REGISTRATION_RECORDS_SUCCESS',
  DOWNLOAD_FACULTY_REGISTRATION_RECORDS_ERROR:
    'DOWNLOAD_FACULTY_REGISTRATION_RECORDS_ERROR',

  DOWNLOAD_REGISTRATION_STATISTICS_REQUEST:
    'DOWNLOAD_REGISTRATION_STATISTICS_REQUEST',
  DOWNLOAD_REGISTRATION_STATISTICS_SUCCESS:
    'DOWNLOAD_REGISTRATION_STATISTICS_SUCCESS',
  DOWNLOAD_REGISTRATION_STATISTICS_ERROR:
    'DOWNLOAD_REGISTRATION_STATISTICS_ERROR',

  SET_REPORTS_CONTEXT: 'SET_REPORTS_CONTEXT',
  SET_STATISTICS_CONTEXT: 'SET_STATISTICS_CONTEXT',

  setReportsContext: (data) => ({
    type: registrationReports.SET_REPORTS_CONTEXT,
    data,
  }),
  setStatisticsContext: (data) => ({
    type: registrationReports.SET_STATISTICS_CONTEXT,
    data,
  }),
  getSummarizedReports: (data) => ({
    type: registrationReports.GET_SUMMARIZED_REPORTS_REQUEST,
    data,
  }),
  getDetailedReports: (data) => ({
    type: registrationReports.GET_DETAILED_REPORTS_REQUEST,
    data,
  }),
  getStudentsReportsRecords: (data) => ({
    type: registrationReports.GET_STUDENTS_REPORTS_RECORDS_REQUEST,
    data,
  }),
  downloadRegisteredStudentsRecords: (data) => ({
    type: registrationReports.DOWNLOAD_REGISTERED_STUDENTS_RECORDS_REQUEST,
    data,
  }),
  downloadUnRegisteredStudentsRecords: (data) => ({
    type: registrationReports.DOWNLOAD_UNREGISTERED_STUDENTS_RECORDS_REQUEST,
    data,
  }),
  downloadFacultyRegisteredRecords: (data) => ({
    type: registrationReports.DOWNLOAD_FACULTY_REGISTRATION_RECORDS_REQUEST,
    data,
  }),
  getRegistrationReportSummary: (data) => ({
    type: registrationReports.GET_REGISTRATION_REPORT_SUMMARY_REQUEST,
    data,
  }),
  getRegistrationStatistics: (data) => ({
    type: registrationReports.GET_REGISTRATION_STATISTICS_REQUEST,
    data,
  }),
  getModularSummaryReport: (context) => ({
    type: registrationReports.GET_MODULAR_REGISTRATION_SUMMARY_REQUEST,
    context,
  }),
  getModularDetailedReport: (context) => ({
    type: registrationReports.GET_MODULAR_REGISTRATION_DETAILS_REQUEST,
    context,
  }),
  getModularStudentReport: (context) => ({
    type: registrationReports.GET_MODULAR_STUDENT_REGISTRATION_DETAILS_REQUEST,
    context,
  }),
  downloadRegistrationStatistics: (data) => ({
    type: registrationReports.DOWNLOAD_REGISTRATION_STATISTICS_REQUEST,
    data,
  }),
};

export default registrationReports;
