const building = {
  ADD_BUILDING_REQUEST: 'ADD_BUILDING_REQUEST',
  ADD_BUILDING_SUCCESS: 'ADD_BUILDING_SUCCESS',
  ADD_BUILDING_ERROR: 'ADD_BUILDING_ERROR',

  GET_BUILDINGS_REQUEST: 'GET_BUILDINGS_REQUEST',
  GET_BUILDINGS_SUCCESS: 'GET_BUILDINGS_SUCCESS',
  GET_BUILDINGS_ERROR: 'GET_BUILDINGS_ERROR',

  GET_BUILDINGS_BY_CAMPUS_REQUEST: 'GET_BUILDINGS_BY_CAMPUS_REQUEST',
  GET_BUILDINGS_BY_CAMPUS_SUCCESS: 'GET_BUILDINGS_BY_CAMPUS_SUCCESS',
  GET_BUILDINGS_BY_CAMPUS_ERROR: 'GET_BUILDINGS_BY_CAMPUS_ERROR',

  DOWNLOAD_BUILDINGS_TEMPLATE_REQUEST: 'DOWNLOAD_BUILDINGS_TEMPLATE_REQUEST',
  DOWNLOAD_BUILDINGS_TEMPLATE_SUCCESS: 'DOWNLOAD_BUILDINGS_TEMPLATE_SUCCESS',
  DOWNLOAD_BUILDINGS_TEMPLATE_ERROR: 'DOWNLOAD_BUILDINGS_TEMPLATE_ERROR',

  DOWNLOAD_ROOMS_TEMPLATE_REQUEST: 'DOWNLOAD_ROOMS_TEMPLATE_REQUEST',
  DOWNLOAD_ROOMS_TEMPLATE_SUCCESS: 'DOWNLOAD_ROOMS_TEMPLATE_SUCCESS',
  DOWNLOAD_ROOMS_TEMPLATE_ERROR: 'DOWNLOAD_ROOMS_TEMPLATE_ERROR',

  UPLOAD_BUILDINGS_REQUEST: 'UPLOAD_BUILDINGS_REQUEST',
  UPLOAD_BUILDINGS_SUCCESS: 'UPLOAD_BUILDINGS_SUCCESS',
  UPLOAD_BUILDINGS_ERROR: 'UPLOAD_BUILDINGS_ERROR',

  UPLOAD_ROOMS_REQUEST: 'UPLOAD_ROOMS_REQUEST',
  UPLOAD_ROOMS_SUCCESS: 'UPLOAD_ROOMS_SUCCESS',
  UPLOAD_ROOMS_ERROR: 'UPLOAD_ROOMS_ERROR',

  DELETE_BUILDINGS_REQUEST: 'DELETE_BUILDINGS_REQUEST',
  DELETE_BUILDINGS_SUCCESS: 'DELETE_BUILDINGS_SUCCESS',
  DELETE_BUILDINGS_ERROR: 'DELETE_BUILDINGS_ERROR',

  DELETE_ROOM_REQUEST: 'DELETE_ROOM_REQUEST',
  DELETE_ROOM_SUCCESS: 'DELETE_ROOM_SUCCESS',
  DELETE_ROOM_ERROR: 'DELETE_ROOM_ERROR',

  EDIT_BUILDINGS_REQUEST: 'EDIT_BUILDINGS_REQUEST',
  EDIT_BUILDINGS_SUCCESS: 'EDIT_BUILDINGS_SUCCESS',
  EDIT_BUILDINGS_ERROR: 'EDIT_BUILDINGS_ERROR',

  EDIT_ROOM_REQUEST: 'EDIT_ROOM_REQUEST',
  EDIT_ROOM_SUCCESS: 'EDIT_ROOM_SUCCESS',
  EDIT_ROOM_ERROR: 'EDIT_ROOM_ERROR',

  SET_BUILDING_CAMPUS: 'SET_BUILDING_CAMPUS',

  addBuilding: (data) => ({
    type: building.ADD_BUILDING_REQUEST,
    data,
  }),
  editRoom: (id, data, campusId) => ({
    type: building.EDIT_ROOM_REQUEST,
    id,
    data,
    campusId,
  }),
  setBuildingCampus: (data) => ({
    type: building.SET_BUILDING_CAMPUS,
    data,
  }),
  getBuildings: (params) => ({
    type: building.GET_BUILDINGS_REQUEST,
    params,
  }),
  getBuildingsByCampus: (campusId) => ({
    type: building.GET_BUILDINGS_BY_CAMPUS_REQUEST,
    campusId,
  }),

  downloadBuildingsTemplate: () => ({
    type: building.DOWNLOAD_BUILDINGS_TEMPLATE_REQUEST,
  }),

  downloadRoomsTemplate: (campusId) => ({
    type: building.DOWNLOAD_ROOMS_TEMPLATE_REQUEST,
    campusId,
  }),

  uploadBuildings: (data) => ({
    type: building.UPLOAD_BUILDINGS_REQUEST,
    data,
  }),

  uploadRooms: (campusId, data) => ({
    type: building.UPLOAD_ROOMS_REQUEST,
    campusId,
    data,
  }),

  deleteBuilding: (campusId, id) => ({
    type: building.DELETE_BUILDINGS_REQUEST,
    campusId,
    id,
  }),

  deleteRoom: (id, campusID) => ({
    type: building.DELETE_ROOM_REQUEST,
    id,
    campusID,
  }),

  editBuilding: (campusId, data, id) => ({
    type: building.EDIT_BUILDINGS_REQUEST,
    campusId,
    data,
    id,
  }),
};

export default building;
