import { uniqBy } from 'lodash';
import { studentRegistrationActions } from '../../actions';
import initialState from '../../initialState';

const studentRegistration = (
  state = initialState.studentRegistration,
  actions
) => {
  switch (actions.type) {
    case studentRegistrationActions.REGISTER_STUDENT_REQUEST:
      return {
        ...state,
        registerError: {},
        registering: true,
      };
    case studentRegistrationActions.REGISTER_STUDENT_SUCCESS:
      return {
        ...state,
        registering: false,
        registerSuccess: actions.data,
      };
    case studentRegistrationActions.REGISTER_STUDENT_ERROR:
      return {
        ...state,
        registerError: actions.error,
        registering: false,
      };
    case studentRegistrationActions.REGISTER_STUDENT_FULLY_REQUEST:
      return {
        ...state,
        registerFullyError: {},
        registeringFully: true,
      };
    case studentRegistrationActions.REGISTER_STUDENT_FULLY_SUCCESS:
      return {
        ...state,
        registeringFully: false,
      };
    case studentRegistrationActions.REGISTER_STUDENT_FULLY_ERROR:
      return {
        ...state,
        registerFullyError: actions.error,
        registeringFully: false,
      };

    case studentRegistrationActions.UPDATE_STUDENT_REGISTRATION_REQUEST:
      return {
        ...state,
        updateRegistrationSuccess: {},
        updateRegistrationError: {},
        updatingRegistration: true,
      };
    case studentRegistrationActions.UPDATE_STUDENT_REGISTRATION_SUCCESS:
      return {
        ...state,
        updatingRegistration: false,
        updateRegistrationSuccess: actions.data,
      };
    case studentRegistrationActions.UPDATE_STUDENT_REGISTRATION_ERROR:
      return {
        ...state,
        updateRegistrationError: actions.error,
        updatingRegistration: false,
      };

    case studentRegistrationActions.DE_REGISTER_STUDENT_REQUEST:
      return {
        ...state,
        deRegisterError: {},
        deRegistering: true,
      };
    case studentRegistrationActions.DE_REGISTER_STUDENT_SUCCESS:
      return {
        ...state,
        deRegistering: false,
        deRegisterSuccess: actions.data,
      };
    case studentRegistrationActions.DE_REGISTER_STUDENT_ERROR:
      return {
        ...state,
        deRegisterError: actions.error,
        deRegistering: false,
      };

    case studentRegistrationActions.ADD_LATE_REGISTRATION_REQUEST:
      return {
        ...state,
        registerError: {},
        registering: true,
      };
    case studentRegistrationActions.ADD_LATE_REGISTRATION_SUCCESS:
      return {
        ...state,
        registering: false,
        registerSuccess: actions.data,
      };
    case studentRegistrationActions.ADD_LATE_REGISTRATION_ERROR:
      return {
        ...state,
        registerError: actions.error,
        registering: false,
      };

    case studentRegistrationActions.GET_REGISTRATION_HISTORY_REQUEST:
      return {
        ...state,
        registrationHistoryError: {},
        fetching: true,
      };
    case studentRegistrationActions.GET_REGISTRATION_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        allRegistrationHistory: uniqBy(
          [
            {
              studentId: actions.studentId,
              studentProgrammeId: actions.studentProgrammeId,
              registrationHistory: actions.data,
            },
            ...state.allRegistrationHistory,
          ],
          'studentProgrammeId'
        ),
      };
    case studentRegistrationActions.GET_REGISTRATION_HISTORY_ERROR:
      return {
        ...state,
        registrationHistoryError: actions.error,
        fetching: false,
      };
    case studentRegistrationActions.GET_ADMINISTRATIVE_REGISTRATION_REQUEST:
      return {
        ...state,
        gettingAdministrativeReg: true,
        administrativeRegistrationSuccess: {},
      };
    case studentRegistrationActions.GET_ADMINISTRATIVE_REGISTRATION_SUCCESS:
      return {
        ...state,
        gettingAdministrativeReg: false,
        administrativeRegistrationSuccess: actions.data,
      };
    case studentRegistrationActions.GET_ADMINISTRATIVE_REGISTRATION_ERROR:
      return {
        ...state,
        gettingAdministrativeReg: false,
        administrativeRegistrationError: actions.error,
      };
    case studentRegistrationActions.REVIEW_ADMINISTRATIVE_REGISTRATION_REQUEST:
      return {
        ...state,
        reviewingAdministrativeReg: true,
        reviewAdministrativeRegSuccess: {},
      };
    case studentRegistrationActions.REVIEW_ADMINISTRATIVE_REGISTRATION_SUCCESS:
      return {
        ...state,
        reviewingAdministrativeReg: false,
        reviewAdministrativeRegSuccess: actions.data,
      };
    case studentRegistrationActions.REVIEW_ADMINISTRATIVE_REGISTRATION_ERROR:
      return {
        ...state,
        reviewingAdministrativeReg: false,
        reviewAdministrativeRegSuccess: actions.error,
      };
    case studentRegistrationActions.APPROVE_ADMINISTRATIVE_REGISTRATION_REQUEST:
      return {
        ...state,
        approvingAdministrativeReg: true,
        approveAdministrativeRegSuccess: {},
      };
    case studentRegistrationActions.APPROVE_ADMINISTRATIVE_REGISTRATION_SUCCESS:
      return {
        ...state,
        approvingAdministrativeReg: false,
        approveAdministrativeRegSuccess: actions.payload,
        approveAdministrativeRegError: {},
      };
    case studentRegistrationActions.APPROVE_ADMINISTRATIVE_REGISTRATION_ERROR:
      return {
        ...state,
        approvingAdministrativeReg: false,
        approveAdministrativeRegError: actions.error,
      };
    case studentRegistrationActions.GET_PREVIOUS_REGISTRATION_REQUEST:
      return {
        ...state,
        previousRegistrationError: {},
        fetchingPreviousRegistration: true,
      };
    case studentRegistrationActions.GET_PREVIOUS_REGISTRATION_SUCCESS:
      return {
        ...state,
        fetchingPreviousRegistration: false,
        allPreviousRegistration:
          actions.data?.length > 0
            ? uniqBy(
                [
                  {
                    studentProgrammeId: actions?.data[0]?.student_programme_id,
                    previousRegistration: actions.data,
                  },
                  ...state.allPreviousRegistration,
                ],
                'studentProgrammeId'
              )
            : [],
      };
    case studentRegistrationActions.GET_PREVIOUS_REGISTRATION_ERROR:
      return {
        ...state,
        previousRegistrationError: actions.error,
        fetchingPreviousRegistration: false,
      };

    case studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_REQUEST:
      return {
        ...state,
        fetchingEnrollmentBalances: true,
        migratedEnrollmentBalanceError: {},
      };
    case studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_SUCCESS:
      return {
        ...state,
        fetchingEnrollmentBalances: false,
        migratedEnrollmentBalances: actions.data,
      };
    case studentRegistrationActions.GET_MIGRATED_ENROLLMENT_BALANCES_ERROR:
      return {
        ...state,
        fetchingEnrollmentBalances: false,
        migratedEnrollmentBalanceError: actions.error,
      };

    case studentRegistrationActions.GET_SEMESTER_EXAM_RECORDS_REQUEST:
      return {
        ...state,
        gettingExamRecords: true,
        getExamRecordsError: {},
      };
    case studentRegistrationActions.GET_SEMESTER_EXAM_RECORDS_SUCCESS:
      return {
        ...state,
        gettingExamRecords: false,
        examinationRecords: actions.data,
      };
    case studentRegistrationActions.GET_SEMESTER_EXAM_RECORDS_ERROR:
      return {
        ...state,
        gettingExamRecords: false,
        getExamRecordsError: actions.error,
      };

    case studentRegistrationActions.ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_REQUEST:
      return {
        ...state,
        addingToExamRecords: true,
        getExamRecordsError: {},
      };
    case studentRegistrationActions.ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_SUCCESS:
      return {
        ...state,
        addingToExamRecords: false,
        addToExamRecordsSuccess: actions.data,
      };
    case studentRegistrationActions.ADD_SINGLE_STUDENT_TO_EXAM_TRACKER_ERROR:
      return {
        ...state,
        addingToExamRecords: false,
        addToExamRecordsError: actions.error,
      };

    case studentRegistrationActions.UPLOAD_EXAM_RECORDS_TEMPLATE_REQUEST:
      return {
        ...state,
        uploadingTemplate: true,
        uploadTemplateSuccess: {},
        uploadTemplateError: {},
      };
    case studentRegistrationActions.UPLOAD_EXAM_RECORDS_TEMPLATE_SUCCESS:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateSuccess: actions.data,
      };
    case studentRegistrationActions.UPLOAD_EXAM_RECORDS_TEMPLATE_ERROR:
      return {
        ...state,
        uploadingTemplate: false,
        uploadTemplateError: actions.error,
      };

    case studentRegistrationActions.DOWNLOAD_EXAM_RECORDS_TEMPLATE_REQUEST:
      return {
        ...state,
        downloadingTemplate: true,
        downloadTemplateError: {},
        downloadTemplateSuccess: {},
      };
    case studentRegistrationActions.DOWNLOAD_EXAM_RECORDS_TEMPLATE_SUCCESS:
      return {
        ...state,
        downloadTemplateSuccess: actions.data,
        downloadingTemplate: false,
      };
    case studentRegistrationActions.DOWNLOAD_EXAM_RECORDS_TEMPLATE_ERROR:
      return {
        ...state,
        downloadTemplateError: actions.error,
        downloadingTemplate: false,
      };

    case studentRegistrationActions.BILL_PREVIOUS_BALANCES_REQUEST:
      return {
        ...state,
        billing: true,
        billingError: {},
      };
    case studentRegistrationActions.BILL_PREVIOUS_BALANCES_SUCCESS:
      return {
        ...state,
        billing: false,
        billedBalances: actions.data,
      };
    case studentRegistrationActions.BILL_PREVIOUS_BALANCES_ERROR:
      return {
        ...state,
        billing: false,
        billingError: actions.error,
      };

    case studentRegistrationActions.SET_SELECTED_REGISTRATION_RECORD:
      return {
        ...state,
        registrationRecord: actions.record,
      };

    default:
      return state;
  }
};
export default studentRegistration;
