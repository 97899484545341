const financialStatementActions = {
  GET_FINANCIAL_STATEMENT_REQUEST: 'GET_FINANCIAL_STATEMENT_REQUEST',
  GET_FINANCIAL_STATEMENT_SUCCESS: 'GET_FINANCIAL_STATEMENT_SUCCESS',
  GET_FINANCIAL_STATEMENT_ERROR: 'GET_FINANCIAL_STATEMENT_ERROR',

  DOWNLOAD_FINANCIAL_STATEMENT_REQUEST: 'DOWNLOAD_FINANCIAL_STATEMENT_REQUEST',
  DOWNLOAD_FINANCIAL_STATEMENT_SUCCESS: 'DOWNLOAD_FINANCIAL_STATEMENT_SUCCESS',
  DOWNLOAD_FINANCIAL_STATEMENT_ERROR: 'DOWNLOAD_FINANCIAL_STATEMENT_ERROR',

  getFinancialStatement: (studentProgrammeId) => ({
    type: financialStatementActions.GET_FINANCIAL_STATEMENT_REQUEST,
    studentProgrammeId,
  }),

  downloadFinancialStatement: (studentProgrammeId) => ({
    type: financialStatementActions.DOWNLOAD_FINANCIAL_STATEMENT_REQUEST,
    studentProgrammeId,
  }),
};

export default financialStatementActions;
