const studentsInitialState = {
  studentEnrollment: {
    enrolling: false,
    enrollError: {},
    enrollSuccess: {},

    allEnrollmentHistory: [],
    fetching: false,
    enrollmentHistoryError: {},

    deEnrolling: false,
    deEnrollSuccess: {},
    deEnrollError: {},

    gettingPendingReinstatements: false,
    pendingReinstatements: [],
    pendingReinstatementsError: {},

    approvingReinstatement: false,
    approveSuccess: {},
    approveError: {},
  },
  studentRegistration: {
    registering: false,
    registerError: {},
    registerSuccess: {},

    registeringFully: false,
    registerFullyError: {},

    deRegistering: false,
    deRegisterSuccess: {},
    deRegisterError: {},

    reviewingAdministrativeReg: false,
    reviewAdministrativeRegSuccess: {},
    reviewAdministrativeRegError: {},

    gettingAdministrativeReg: false,
    administrativeRegistrationSuccess: {},
    administrativeRegistrationError: {},

    approvingAdministrativeReg: false,
    approveAdministrativeRegSuccess: {},
    approveAdministrativeRegError: {},

    selectedAdministrativeRecord: {},

    allRegistrationHistory: [],
    fetching: false,
    registrationHistoryError: {},

    updateRegistrationSuccess: {},
    updateRegistrationError: {},
    updatingRegistration: false,

    allPreviousRegistration: [],
    previousRegistrationError: {},
    fetchingPreviousRegistration: false,

    migratedEnrollmentBalances: [],
    migratedEnrollmentBalanceError: {},
    fetchingEnrollmentBalances: false,

    billedBalances: {},
    billingError: {},
    billing: false,

    gettingExamRecords: false,
    examinationRecords: [],
    getExamRecordsError: {},

    addingToExamRecords: false,
    addToExamRecordsSuccess: {},
    addToExamRecordsError: {},

    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateSuccess: {},
    uploadTemplateError: {},

    registrationRecord: {},
  },
  semesterEvent: {
    allCurrentSemesterEvents: [],
    loading: false,
    loadError: {},
  },
  registrationReports: {
    summarizedReports: [],
    detailedReports: [],
    studentsReportsRecords: [],
    summarizedModularReports: [],
    detailedModularReports: [],
    studentModularRecords: [],
    gettingStudentReports: false,
    reportsContext: {},
    statisticsContext: {},
    gettingDetailedReports: false,
    gettingDetailedReportsError: {},
    gettingSummarizedReports: false,
    gettingSummarizedReportsError: {},
    loading: false,
    loadError: {},

    downloadingRegisteredStudents: false,
    downloadRegisteredError: {},
    downloadRegisteredSuccess: {},

    downloadingUnRegisteredStudents: false,
    downloadUnRegisteredError: {},
    downloadUnRegisteredSuccess: {},

    fetchingReportSummary: false,
    fetchReportSummaryError: {},
    reportSummary: [],

    downloadingFacultyReport: false,
    downloadFacultyReportError: {},
    downloadFacultyReportSuccess: {},

    gettingRegistrationStats: false,
    registrationStatistics: {},
    getRegistrationStatsError: {},

    downloadingStats: false,
    downloadStatsError: {},
    downloadStatsSuccess: {},
  },
  previousRegistration: {
    downloadingTemplate: false,
    downloadTemplateError: {},
    downloadTemplateSuccess: {},

    uploadingTemplate: false,
    uploadTemplateSuccess: {},
    uploadTemplateError: {},

    editingRecord: false,
    editRecordSuccess: {},
    editRecordError: {},

    deletingRecord: false,
    deleteRecordSuccess: {},
    deleteRecordError: {},

    generatingAdmissionLetters: true,
    generateAdmissionLetterError: {},
    generateRegistrationNumberSuccess: {},
  },
};
export default studentsInitialState;
